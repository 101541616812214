import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import { Timestampable }      from 'helpers/traits';
import { Blamable }           from 'helpers/traits';
import InvoicePrivateApiModel from 'modelx/models/abstracts/InvoicePrivateApiModel';

@model.cacheDuration()
@model.staticLabel('DueDateType')
@model.urnResource('due_date_type')
@doc.path('/due_date_types/{?id}')
export default class DueDateTypeModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'reference': InvoiceDueDateTypeReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'position': string;
		'reference': string;
		'updatedAt': string;
	}> = {};

	@doc.string declare label: string;
	@doc.number declare position: number;
	@doc.string declare reference: InvoiceDueDateTypeReference;

}