import model                       from 'decorators/model';
import { urn }                     from 'helpers/ModelDictionary';
import { Blamable }                from 'helpers/traits';
import { Timestampable }           from 'helpers/traits';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

@urn('$', 'intervention', 'task_zone_group')
@model.staticLabel('Groupe de site')
export default class TaskZoneGroupModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {

	static get path(): string {
		return '/task_zone_groups/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'name': string;
		'updatedAt': string;
	}> = {};

	public get name(): string {
		return this.get('name', '');
	}
}