import BillingFrequencyModel from 'Models/invoice/BillingFrequencyModel';
import DueDateTypeModel      from 'Models/invoice/DueDateTypeModel';
import InvoicingGroupModel   from 'Models/invoice/InvoicingGroupModel';
import PaymentModeModel      from 'Models/invoice/PaymentModeModel';
import model                 from 'decorators/model';
import { computed }          from 'mobx';
import { getIdFromUrn }      from 'tools/UrnTools';
import SalesPrivateApiModel  from '../../abstracts/SalesPrivateApiModel';

export default class QuotationBillingGroupModel extends SalesPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		quotation: id;
	}> = {};

	static get path(): string {
		return '/quotation_billing_groups/{?id}';
	}

	@computed
	public get billingDays(): number[] {
		return this.get('billingDays', []);
	}

	@computed
	public get billingFrequencyUrn(): string {
		return this.get('billingFrequencyUrn');
	}

	@computed
	public get billingFrequencyId(): id {
		return parseInt(getIdFromUrn(this.billingFrequencyUrn));
	}

	@computed
	public get billingFrequencyIri(): string {
		if (!this.billingFrequencyId) {
			return '';
		}

		return `/invoice/quotation_billing_groups/${this.billingFrequencyId}`;
	}

	@computed
	public get dueDateDelay(): number {
		return this.get('dueDateDelay');
	}

	@computed
	public get dueDateTypeUrn(): string {
		return this.get('dueDateTypeUrn');
	}

	@computed
	public get freeReference(): string {
		return this.get('freeReference');
	}

	@computed
	public get invoicingGroupUrn(): string {
		return this.get('invoicingGroupUrn');
	}
	@computed
	public get invoicingGroupId(): id {
		return parseInt(getIdFromUrn(this.invoicingGroupUrn));
	}

	@computed
	public get invoicingGroupIri(): string {
		if (!this.invoicingGroupId) {
			return '';
		}

		return `/invoice/quotation_billing_groups/${this.invoicingGroupId}`;
	}

	@computed
	public get name(): string {
		return this.get('name');
	}

	@computed
	public get note(): string {
		return this.get('note', '');
	}

	@computed
	public get paymentModeUrn(): string {
		return this.get('paymentModeUrn');
	}
	@computed
	public get paymentModeId(): id {
		return parseInt(getIdFromUrn(this.paymentModeUrn));
	}

	@computed
	public get paymentModeIri(): string {
		if (!this.paymentModeId) {
			return '';
		}

		return `/invoice/quotation_billing_groups/${this.paymentModeId}`;
	}

	@computed
	public get sourceUrn(): string {
		return this.get('sourceUrn');
	}

	@model.resolvableUrn({ attributeName: 'paymentModeUrn' })
	public declare paymentMode: PaymentModeModel;

	@model.resolvableUrn({ attributeName: 'invoicingGroupUrn' })
	public declare invoicingGroup: InvoicingGroupModel;

	@model.resolvableUrn({ attributeName: 'billingFrequencyUrn' })
	public declare billingFrequency: BillingFrequencyModel;
	
	@model.resolvableUrn({ attributeName: 'dueDateTypeUrn' })
	public declare dueDateType: DueDateTypeModel;
}
