import doc                  from 'decorators/doc';
import model                from 'decorators/model';
import { Timestampable }    from 'helpers/traits';
import SalesPrivateApiModel from 'modelx/models/abstracts/SalesPrivateApiModel';

@model.cacheDuration()
@model.staticLabel('ItemEventExecutionStatus')
@model.urnResource('item_event_execution_status')
@doc.path('/item_event_execution_statuses/{?id}')
export default class ItemEventExecutionStatusModel extends Timestampable(SalesPrivateApiModel) {
	public _filters: ModelFiltersExtended<{
		'reference': SalesItemEventExecutionStatusReference;
	}> = {};

	public _sorts: ModelSortsExtended<unknown> = {};

	@doc.string declare label: string;
	@doc.string declare reference: SalesItemEventExecutionStatusReference;
}