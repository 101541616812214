import ItemEventItemActivityBillingStatusModel   from 'Models/sales/ItemEventItemActivityBillingStatusModel';
import ItemEventItemActivityExecutionStatusModel from 'Models/sales/ItemEventItemActivityExecutionStatusModel';
import QuotationItemActivityModel                from 'Models/sales/QuotationItemActivityModel';
import QuotationItemEventModel                   from 'Models/sales/QuotationItemEventModel';
import doc                                       from 'decorators/doc';
import model                                     from 'decorators/model';
import { Timestampable }                         from 'helpers/traits';
import { Blamable }                              from 'helpers/traits';
import SalesPrivateApiModel                      from 'modelx/models/abstracts/SalesPrivateApiModel';

@model.urnResource('quotation_item_event_item_activity')
@doc.path('/quotation_item_event_item_activities/{?id}')
export default class QuotationItemEventItemActivityModel extends Blamable(Timestampable(SalesPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'itemEventItemActivityBillingStatus': id;
		'itemEventItemActivityBillingStatus.reference': SalesItemEventItemActivityBillingStatusReference;
		'itemEventItemActivityExecutionStatus': id;
		'itemEventItemActivityExecutionStatus.reference': SalesItemEventItemActivityExecutionStatusReference;
		'quotationItemActivity': id;
		'quotationItemActivity.activity': id;
		'quotationItemActivity.activity.activityType': id;
		'quotationItemActivity.locationUrn': Urn;
		'quotationItemActivity.quotationItem': id;
		'quotationItemActivity.quotationItem.quotation': id;
		'quotationItemActivity.quotationItem.quotation.owner.clientUrn': Urn;
		'quotationItemActivity.quotationItem.quotation.owner.companyUrn': Urn;
		'quotationItemActivity.quotationItem.quotation.owner.enterpriseUrn': Urn;
		'quotationItemActivity.quotationItem.quotation.owner.ownerEnterprisePartition.enterprisePartitionUrn': Urn;
		'quotationItemActivity.quotationItem.quotation.owner.ownerSubPartition.ownerPartition.partitionUrn': Urn;
		'quotationItemActivity.quotationItem.quotation.owner.ownerSubPartition.subPartitionUrn': Urn;
		'quotationItemEvent': id;
		'quotationItemEvent.scheduleEndDate[after]': string | Date;
		'quotationItemEvent.scheduleEndDate[before]': string | Date;
		'quotationItemEvent.scheduleEndDate[strictly_after]': string | Date;
		'quotationItemEvent.scheduleEndDate[strictly_before]': string | Date;
		'quotationItemEvent.scheduleStartDate[after]': string | Date;
		'quotationItemEvent.scheduleStartDate[before]': string | Date;
		'quotationItemEvent.scheduleStartDate[strictly_after]': string | Date;
		'quotationItemEvent.scheduleStartDate[strictly_before]': string | Date;
		'sourceUrn': Urn;
	}> = {};

	public _sorts: ModelSortsExtended<unknown> = {};

	@doc.model(ItemEventItemActivityBillingStatusModel) declare itemEventItemActivityBillingStatus: ItemEventItemActivityBillingStatusModel;
	@doc.model(ItemEventItemActivityExecutionStatusModel) declare itemEventItemActivityExecutionStatus: ItemEventItemActivityExecutionStatusModel;
	@doc.model(QuotationItemActivityModel) declare quotationItemActivity: QuotationItemActivityModel;
	@doc.model(QuotationItemEventModel) declare quotationItemEvent: QuotationItemEventModel;
}