import SourceModel          from 'Models/invoice/SourceModel';
import ItemDisplayModeModel from 'Models/sales/ItemDisplayModeModel';
import QuotationModel       from 'Models/sales/QuotationModel';
import doc                  from 'decorators/doc';
import model                from 'decorators/model';
import { Timestampable }    from 'helpers/traits';
import { Blamable }         from 'helpers/traits';
import SalesPrivateApiModel from 'modelx/models/abstracts/SalesPrivateApiModel';

@model.staticLabel('Prestation', false)
@model.urnResource('quotation_item')
@doc.path('/quotation_items/{?id}')
export default class QuotationItemModel extends Blamable(Timestampable(SalesPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'quotation': id;
		'sourceUrn': Urn;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'position': string;
	}> = {};

	@doc.string declare description: string;
	@doc.number declare discount: number;
	@doc.model(ItemDisplayModeModel) declare itemDisplayMode: ItemDisplayModeModel;
	@doc.number declare position: number;
	@doc.string declare priceDetails: string;
	@doc.string declare publicComment: string;
	@doc.number declare quantity: number;
	@doc.model(QuotationModel) declare quotation: QuotationModel;
	@doc.modelUrn(SourceModel) declare source: SourceModel;
	@doc.string declare title: string;
}