import ContractIterationItemActivityModel        from 'Models/sales/ContractIterationItemActivityModel';
import ContractIterationItemEventModel           from 'Models/sales/ContractIterationItemEventModel';
import ItemEventItemActivityBillingStatusModel   from 'Models/sales/ItemEventItemActivityBillingStatusModel';
import ItemEventItemActivityExecutionStatusModel from 'Models/sales/ItemEventItemActivityExecutionStatusModel';
import doc                                       from 'decorators/doc';
import model                                     from 'decorators/model';
import { Timestampable }                         from 'helpers/traits';
import { Blamable }                              from 'helpers/traits';
import SalesPrivateApiModel                      from 'modelx/models/abstracts/SalesPrivateApiModel';

@model.staticLabel('Passage de contrat')
@model.urnResource('contract_iteration_item_event_item_activity')
@doc.path('/contract_iteration_item_event_item_activities/{?id}')
export default class ContractIterationItemEventItemActivityModel extends Blamable(Timestampable(SalesPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'contractIterationItemActivity': id;
		'contractIterationItemActivity.activity': id;
		'contractIterationItemActivity.activity.activityType': id;
		'contractIterationItemActivity.contractIterationItem': id;
		'contractIterationItemActivity.contractIterationItem.contractIteration': id;
		'contractIterationItemActivity.contractIterationItem.contractIteration.contract': id;
		'contractIterationItemActivity.contractIterationItem.contractIteration.contract.owner.clientUrn': Urn;
		'contractIterationItemActivity.contractIterationItem.contractIteration.contract.owner.companyUrn': Urn;
		'contractIterationItemActivity.contractIterationItem.contractIteration.contract.owner.enterpriseUrn': Urn;
		'contractIterationItemActivity.contractIterationItem.contractIteration.contract.owner.ownerEnterprisePartition.enterprisePartitionUrn': Urn;
		'contractIterationItemActivity.contractIterationItem.contractIteration.contract.owner.ownerSubPartition.ownerPartition.partitionUrn': Urn;
		'contractIterationItemActivity.contractIterationItem.contractIteration.contract.owner.ownerSubPartition.subPartitionUrn': Urn;
		'contractIterationItemActivity.locationUrn': Urn;
		'contractIterationItemEvent': id;
		'contractIterationItemEvent.scheduleEndDate[after]': string | Date;
		'contractIterationItemEvent.scheduleEndDate[before]': string | Date;
		'contractIterationItemEvent.scheduleEndDate[strictly_after]': string | Date;
		'contractIterationItemEvent.scheduleEndDate[strictly_before]': string | Date;
		'contractIterationItemEvent.scheduleStartDate[after]': string | Date;
		'contractIterationItemEvent.scheduleStartDate[before]': string | Date;
		'contractIterationItemEvent.scheduleStartDate[strictly_after]': string | Date;
		'contractIterationItemEvent.scheduleStartDate[strictly_before]': string | Date;
		'itemEventItemActivityBillingStatus': id;
		'itemEventItemActivityBillingStatus.reference': SalesItemEventItemActivityBillingStatusReference;
		'itemEventItemActivityExecutionStatus': id;
		'itemEventItemActivityExecutionStatus.reference': SalesItemEventItemActivityExecutionStatusReference;
		'sourceUrn': Urn;
	}> = {};

	public _sorts: ModelSortsExtended<unknown> = {};

	@doc.model(ContractIterationItemActivityModel) declare contractIterationItemActivity: ContractIterationItemActivityModel;
	@doc.model(ContractIterationItemEventModel) declare contractIterationItemEvent: ContractIterationItemEventModel;
	@doc.model(ItemEventItemActivityBillingStatusModel) declare itemEventItemActivityBillingStatus: ItemEventItemActivityBillingStatusModel;
	@doc.model(ItemEventItemActivityExecutionStatusModel) declare itemEventItemActivityExecutionStatus: ItemEventItemActivityExecutionStatusModel;
}