import PartitionModel    from 'Models/partition/PartitionModel';
import SubPartitionModel from 'Models/partition/SubPartitionModel';
import AbstractApiModel  from 'modelx/models/abstracts/AbstractApiModel';
import { appStore }      from 'stores';
import browserHistory    from 'tools/browserHistory';

type P = Record<string, string | number | (string | number)[]> | string;

const subPartitionPath = (path: string) => `/sub_partitions/${appStore.subPartition?.id || ''}/${path}`
	.replace('//', '/');
const path = (path: string, params?: P) => {
	let p = '/' + path;

	if (params) {
		if (typeof params === 'string') {
			p += '?' + params;
		} else {
			p += '?' + new URLSearchParams(params as never).toString();
		}
	}

	return p.replace('//', '/');
};

const to = (str: string, redirect?: boolean, params?: P) => {
	const url = path(str, params);

	if (redirect) {
		browserHistory.push(url);
	}

	return url;
};
const toSubPartition = (path: string, redirect?: boolean, params?: P) => to(subPartitionPath(path), redirect, params);

const toP = (str: string, p: PartitionModel, redirect?: boolean, params?: P) => {
	return p.isLoaded ? to(`${p.url}${`/${str}`.replace('//', '/')}`, redirect, params) : '';
};

export default {
	toActivityList: (r?: boolean, p?: P) => to(`activities`, r, p),
	toAgedTrialBalanceList: (r?: boolean, p?: AgedBalancePageFilters) => (
		toSubPartition('aged_trial_balance', r, p as never)
	),
	toAlertContractAdministrativeList: (r?: boolean, p?: P) => to(`alerts/contracts/administrative`, r, p),
	toAlertOperationalList: (r?: boolean, p?: P) => to(`alerts/operational`, r, p),
	toAlertQuotationAdministrativeList: (r?: boolean, p?: P) => to(`alerts/quotations/administrative`, r, p),
	toBillable: (id: id, r?: boolean) => toSubPartition(`billables/${id}`, r),
	toBillingPeriod: (id: id, r?: boolean) => to(`billing_periods/${id}`, r),
	toBillingPeriodList: (r?: boolean, p?: P) => to(`billing_periods`, r, p),
	toBillingSummary: (r?: boolean) => to(`billing_summary`, r),
	toClient: (id: id, r?: boolean) => toSubPartition(`clients/${id}`, r),
	toClientList: (r?: boolean, p?: P) => toSubPartition('clients', r, p),
	toCollectableList: (r?: boolean, p?: P) => to(`collectables`, r, p),
	toCompanyList: (r?: boolean, p?: P) => to('companies', r, p),
	toContact: (id: id, r?: boolean) => to(`contacts/${id}`, r),
	toContactList: (r?: boolean, p?: P) => to(`contacts`, r, p),
	toContract: (id: id, r?: boolean) => to(`contracts/${id}`, r),
	toContractContractualCalendar: (id: id, r?: boolean) => to(`/contract_iterations/${id}/contractual-calendar`, r),
	toContractIteration: (id: id, r?: boolean) => to(`contract_iterations/${id}`, r),
	toContractIterationItemAdd: (id: id, r?: boolean) => to(`contract_iteration_items/add/${id}`, r),
	toContractIterationItemEventItemActivity: (r?: boolean, p?: P) => toSubPartition(`contract_iteration_item_event_item_activities`, r, p),
	toContractIterationList: (r?: boolean, p?: P) => toSubPartition('contract_iterations', r, p),
	toContractIterationStatisticList: (r?: boolean, p?: P) => toSubPartition(`contract_iteration_statistics`, r, p),
	toContractList: (r?: boolean, p?: P) => toSubPartition('contracts', r, p),
	toDashboard: (m: AbstractApiModel, r?: boolean) => r ? m.goTo() : m.pathTo(),
	toEnterprise: (id: id, r?: boolean) => to(`enterprises/${id}`, r),
	toEnterpriseList: (r?: boolean, p?: P) => to('enterprises', r, p),
	toEnterprisePartition: (id: id, r?: boolean) => toSubPartition(`enterprise_partitions/${id}`, r),
	toEnterprisePartitionList: (r?: boolean, p?: P) => to('enterprise_partitions', r, p),
	toFiles: (r?: boolean) => to(`/files`, r),
	toHome: (r?: boolean, p?: P) => to(`/`, r, p),
	toInstallation: (id: id, r?: boolean) => to(`installations/${id}`, r),
	toIntervention: (id: id, r?: boolean) => to(`interventions/${id}`, r),
	toInterventionGroup: (id: id, r?: boolean) => to(`intervention_groups/${id}`, r),
	toInterventionGroupList: (r?: boolean, p?: P) => toSubPartition(`intervention_groups`, r, p),
	toInterventionList: (r?: boolean, p?: P) => toSubPartition(`interventions`, r, p),
	toInterventionStatisticList: (r?: boolean, p?: P) => toSubPartition(`intervention_statistics`, r, p),
	toInvoice: (id: id, r?: boolean) => to(`invoices/${id}`, r),
	toInvoiceItemList: (r?: boolean, p?: P) => to(`invoice_items`, r, p),
	toInvoiceList: (r?: boolean, p?: P) => toSubPartition('invoices', r, p),
	toList: (m: typeof AbstractApiModel, r?: boolean, p?: P) => to(m.pathToList, r, p),
	toLogin: (r?: boolean, p?: P) => to('/login', r, p),
	toOperation: (id: id, r?: boolean) => to(`operations/${id}`, r),
	toOperationList: (r?: boolean, p?: P) => to(`operations`, r, p),
	toOrderPartList: (r?: boolean, p?: P) => to(`order_parts`, r, p),
	toPlanning: (id: id, r?: boolean) => to(`plannings/${id}`, r),
	toPlanningView: (id: id, r?: boolean, p?: P) => to(`plannings/${id}/planning-view`, r, p),
	toProperty: (id: id, r?: boolean) => to(`properties/${id}`, r),
	toPropertyList: (r?: boolean, p?: P) => to(`properties`, r, p),
	toQuotation: (id: id, r?: boolean) => to(`quotations/${id}`, r),
	toQuotationContractualCalendar: (id: id, r?: boolean) => to(`/quotations/${id}/contractual-calendar`, r),
	toQuotationItemAdd: (id: id, r?: boolean) => to(`quotation_items/add/${id}`, r),
	toQuotationItemEventItemActivity: (r?: boolean, p?: P) => toSubPartition(`quotation_item_event_item_activities`, r, p),
	toQuotationList: (r?: boolean, p?: P) => toSubPartition('quotations', r, p),
	toQuotationRequest: (id: id, r?: boolean) => to(`quotation_requests/${id}`, r),
	toQuotationRequestList: (r?: boolean, p?: P) => toSubPartition(`quotation_requests`, r, p),
	toQuotationStatisticList: (r?: boolean, p?: P) => toSubPartition(`quotation_statistics`, r, p),
	toReportDashboard: (id: id, r?: boolean) => to(`reports/${id}`, r),
	toReportList: (r?: boolean, p?: P) => toSubPartition('reports', r, p),
	toResourcePlanning: (r?: boolean, p?: P) => to('resource-planning-view', r, p),
	toSkill: (id: id, r?: boolean) => to(`skills/${id}`, r),
	toStaffMember: (id: id, r?: boolean) => toSubPartition(`staff_members/${id}`, r),
	toStaffMemberList: (r?: boolean, p?: P) => toSubPartition(`staff_members`, r, p),
	toSubPartitionUrl: (sp: SubPartitionModel, redirect?: boolean) => toP('', sp.partition, redirect, { toSP: sp.id }),
	toSupplierContact: (id: id, r?: boolean) => to(`supplier_contacts/${id}`, r),
	toSupplierContactList: (r?: boolean, p?: P) => to(`supplier_contacts`, r, p),
	toSupplierEnterpriseDirectoryList: (r?: boolean, p?: P) => to('supplier_enterprises_directory', r, p),
	toSupplierServiceDashboard: () => to(`supplier_dashboard`),
	toTask: (id: id, r?: boolean) => to(`tasks/${id}`, r),
	toTaskGroup: (id: id, r?: boolean) => to(`task_groups/${id}`, r),
	toTaskList: (r?: boolean, p?: P) => toSubPartition(`tasks`, r, p),
	toTaskOperationList: (r?: boolean, p?: P) => toSubPartition(`task_operations`, r, p),
	toTaskZone: (id: id, r?: boolean) => toSubPartition(`task_zones/${id}`, r),
	toTaskZoneList: (r?: boolean, p?: P) => toSubPartition('task_zones', r, p),
	toVehicle: (id: id, r?: boolean) => to(`vehicles/${id}`, r),
	toVehicleRiskList: (r?: boolean, p?: P) => to('vehicles_risks', r, p),
	toVehicleServiceDashboard: () => to(`vehicle_dashboard`),
};
