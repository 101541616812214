import doc                  from 'decorators/doc';
import model                from 'decorators/model';
import { Timestampable }    from 'helpers/traits';
import SalesPrivateApiModel from 'modelx/models/abstracts/SalesPrivateApiModel';

@model.cacheDuration()
@model.staticLabel('Statut de facturation')
@model.urnResource('item_event_item_activity_billing_status')
@doc.path('/item_event_item_activity_billing_statuses/{?id}')
export default class ItemEventItemActivityBillingStatusModel extends Timestampable(SalesPrivateApiModel) {
	public _filters: ModelFiltersExtended<{
		'reference': SalesItemEventItemActivityBillingStatusReference;
	}> = {};

	public _sorts: ModelSortsExtended<unknown> = {};

	@doc.string declare label: string;
	@doc.string declare reference: SalesItemEventItemActivityBillingStatusReference;

	public get position() {
		switch (this.reference) {
			case 'long_delay_in_payment':
				return 8;
			case 'paid':
				return 7;
			case 'to_pay':
				return 6;
			case 'billable_canceled':
				return 8;
			case 'billable':
				return 4;
			case 'not_to_bill':
				return 5;
			case 'blocked_to_bill':
				return 2;
			case 'to_bill':
				return 3;
			case 'not_applicable':
				return 0;
			case 'waiting':
				return 1;
		}
	}

	public get color() {
		switch (this.reference) {
			case 'long_delay_in_payment':
				return '#e55039';
			case 'paid':
				return '#78e08f';
			case 'to_pay':
				return '#4a69bd';
			case 'billable_canceled':
				return '#e58e26';
			case 'billable':
				return '#079992';
			case 'not_to_bill':
				return '#b71540';
			case 'blocked_to_bill':
				return '#82ccdd';
			case 'to_bill':
				return '#1e3799';
			case 'not_applicable':
				return '#5c6382';
			case 'waiting':
				return '#f8c291';
		}
	}
}