/* eslint-disable @typescript-eslint/no-explicit-any */
import Button                  from 'antd/lib/button';
import { ButtonProps }         from 'antd/lib/button';
import Dropdown                from 'antd/lib/dropdown';
import { MenuProps }           from 'antd/lib/menu';
import { ModalFuncProps }      from 'antd/lib/modal';
import Space                   from 'antd/lib/space';
import Spin                    from 'antd/lib/spin';
import { contextModal }        from 'components/AppForm';
import DisabledTooltip         from 'components/DisabledTooltip';
import Link                    from 'components/Link';
import Tooltip                 from 'components/Tooltip';
import _omit                   from 'lodash/omit';
import React                   from 'react';
import getTextFromElement      from 'tools/getTextFromElement';
import notificationApiError    from 'tools/notificationApiError';
import { notificationSuccess } from 'tools/notification';

export interface IButtonEllipsisAction {
	confirm?: Partial<ModalFuncProps> | string;
	danger?: boolean;
	disabled?: boolean;
	disabledMessage?: string | string[];
	disabledOptions?: React.ComponentProps<typeof DisabledTooltip>;
	divider?: boolean;
	hidden?: boolean;
	icon?: React.ReactNode;
	label: React.ReactNode;
	link?: string;
	linkTarget?: '_self' | '_blank' | '_parent' | '_top';
	loading?: boolean;
	onClick?: () => Promise<any> | void;
	successMessage?: string;
}

interface IButtonEllipsis extends ButtonProps {
	actions: IButtonEllipsisAction[];
	forceButton?: boolean;
	forceDropdown?: boolean;
	stopPropagation?: boolean;
}

export default class ButtonEllipsis extends React.Component<IButtonEllipsis> {
	public render() {
		const { actions, forceButton, forceDropdown, icon, stopPropagation } = this.props;

		const buttonProps = _omit(this.props, ['actions', 'stopPropagation', 'forceButton', 'forceDropdown']);

		const loading = actions.some(action => action.loading);

		if (!actions.length) {
			return null;
		}

		const visibleActions = actions.filter(action => !action.hidden);

		if (forceButton || (visibleActions.length === 1 && !forceDropdown && visibleActions[0].icon && !visibleActions[0].link)) {
			return (
				<Space>
					{visibleActions.map((action, idx) => {
						const icon = action.loading ? <Spin size="small" style={{ marginRight: 4 }} /> : action.icon;

						return (
							<Tooltip destroyTooltipOnHide key={idx} title={action.label}>
								<DisabledTooltip
									disabled={action.disabled}
									messages={action.disabledMessage}
									placement="bottomRight"
									{...action.disabledOptions}
								>
									<Button
										{...buttonProps}
										danger={action.danger && !action.disabled}
										disabled={action.disabled}
										icon={forceButton ? icon : undefined}
										onClick={e => this._onClick(e, action)}
									>
										{(buttonProps.icon || forceButton) ? action.label : icon}
									</Button>
								</DisabledTooltip>
							</Tooltip>
						);
					})}
				</Space>
			);
		}

		const items: MenuProps['items'] = actions
			.filter(action => !action.hidden && (getTextFromElement(action.label) || action.divider))
			.map((action, idx) => {
				if (action.divider) {
					return { type: 'divider' };
				}

				const icon = action.loading ? <Spin size="small" style={{ marginRight: 4 }} /> : action.icon;

				return {
					danger: action.danger && !action.disabled,
					disabled: action.disabled,
					key: idx,
					label: (
						<DisabledTooltip
							disabled={action.disabled}
							messages={action.disabledMessage}
							placement="rightTop"
							{...action.disabledOptions}
						>
							{(!action.disabled && action.link) ? (
								<Link target={action.linkTarget} to={action.link}>
									{icon} {action.label}
								</Link>
							) : (
								<span>{icon} {action.label}</span>
							)}
						</DisabledTooltip>
					),
					onClick: m => this._onClick(m.domEvent as never, action),
				};
			});

		return (
			<Dropdown menu={{ items }} trigger={['click']}>
				<Button onClick={e => stopPropagation && e.stopPropagation()} {...buttonProps} icon={null}>
					{loading ? <Spin size="small" /> : (icon || `...`)}
				</Button>
			</Dropdown>
		);
	}

	private _execAction = async (action: IButtonEllipsisAction) => {
		if (action.onClick) {
			action.loading = true;
			this.forceUpdate();
			try {
				await action.onClick();

				if (action.successMessage) {
					notificationSuccess(action.successMessage);
				}
			} catch (err) {
				notificationApiError(err);
			} finally {
				action.loading = false;
				this.forceUpdate();
			}
		}
	};

	private _onClick = (e: React.MouseEvent<HTMLElement>, action: IButtonEllipsisAction) => {
		if (this.props.stopPropagation) {
			e.stopPropagation();
		}

		if (action.onClick) {
			if (action.confirm) {
				const confirm = typeof action.confirm === 'string' ? { title: action.confirm } : action.confirm;

				contextModal.confirm({
					cancelText: `Annuler`,
					className: 'button-ellipsis-confirm-modal',
					maskClosable: true,
					okText: `Continuer`,
					onOk: () => this._execAction(action),
					title: 'Attention !',
					width: 450,

					...confirm,
				});
			} else {
				return this._execAction(action);
			}
		}
	};
}
