import { QuotationActorGroupReference } from 'Models/sales/QuotationActorGroupModel';

export const QUOTATION_ACTOR_GROUP_COMMERCIAL = {
	label: 'Commercial',
	reference: 'QUOTATION_ACTOR_GROUP_COMMERCIAL' as QuotationActorGroupReference,
};

export const QUOTATION_ACTOR_GROUP_BUYING = {
	label: 'Contact Achat',
	reference: 'QUOTATION_ACTOR_GROUP_BUYING' as QuotationActorGroupReference,
};

export const QUOTATION_ACTOR_GROUP_ACC_REPORTING_RECIPIENT = {
	label: 'Destinataire des rapports ACC',
	reference: 'QUOTATION_ACTOR_GROUP_ACC_REPORTING_RECIPIENT' as QuotationActorGroupReference,
};

export default {
	QUOTATION_ACTOR_GROUP_ACC_REPORTING_RECIPIENT,
	QUOTATION_ACTOR_GROUP_BUYING,
	QUOTATION_ACTOR_GROUP_COMMERCIAL,
};
