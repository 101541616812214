type RequestFunction<T> = () => Promise<T>;

interface QueueItem<T> {
	reject: (reason?: unknown) => void;
	requestFunction: RequestFunction<T>;
	resolve: (value: T | PromiseLike<T>) => void;
}

export default class RequestQueue<T> {
	private _activeRequests = 0; // Nombre de requêtes en cours
	private readonly _maxConcurrent: number; // Limite de requêtes simultanées
	private _queue: QueueItem<T>[] = []; // File d'attente des requêtes

	constructor(maxConcurrent = 10) {
		this._maxConcurrent = maxConcurrent;
	}

	addRequest(requestFunction: RequestFunction<T>): Promise<T> {
		return new Promise((resolve, reject) => {
			this._queue.push({ reject, requestFunction, resolve });
			this.runNext(); // Tente d'exécuter la requête immédiatement
		});
	}

	private runNext(): void {
		// Ne rien faire s'il n'y a plus de place ou si la file est vide
		if (this._activeRequests >= this._maxConcurrent || this._queue.length === 0) {
			return;
		}

		// Récupérer la prochaine requête dans la file d'attente
		const { reject, requestFunction, resolve } = this._queue.shift() as QueueItem<T>;

		// Incrémente le compteur des requêtes actives
		this._activeRequests++;

		// Exécuter la requête
		requestFunction()
			.then(result => {
				resolve(result); // Résout la promesse de la requête
			})
			.catch(error => {
				reject(error); // Rejette la promesse de la requête
			})
			.finally(() => {
				this._activeRequests--; // Décrémente le compteur des requêtes actives
				this.runNext(); // Tente d'exécuter la prochaine requête
			});
	}
}

// // Exemple d'utilisation
// const requestQueue = new RequestQueue<string>(30);
//
// // Fonction exemple de requête qui simule une tâche asynchrone
// function exampleRequest(): Promise<string> {
// 	return new Promise(resolve => setTimeout(() => resolve('Request completed'), 1000));
// }
//
// // Ajout de 35 requêtes à la queue
// for (let i = 0; i < 202; i++) {
// 	requestQueue.addRequest(exampleRequest).then(result => {
// 		console.log(`Requête ${i + 1}: ${result}`);
// 	});
// }
