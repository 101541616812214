import { ApiCollection }          from 'Collections/ApiCollection';
import ContactModel               from 'Models/directory/ContactModel';
import ContactTypeModel           from 'Models/intervention/ContactTypeModel';
import InterventionModel          from 'Models/intervention/InterventionModel';
import InterventionOperationModel from 'Models/intervention/InterventionOperationModel';
import InterventionStatusModel    from 'Models/intervention/InterventionStatusModel';
import OperationModel             from 'Models/intervention/OperationModel';
import TagModel                   from 'Models/intervention/TagModel';
import TaskModel                  from 'Models/intervention/TaskModel';
import TaskOperationExtraModel    from 'Models/intervention/TaskOperationExtraModel';
import TaskOperationModel         from 'Models/intervention/TaskOperationModel';
import TaskOperationStatusModel   from 'Models/intervention/TaskOperationStatusModel';
import TaskTagModel               from 'Models/intervention/TaskTagModel';
import TaskTaskTagModel           from 'Models/intervention/TaskTaskTagModel';
import TaskZoneModel              from 'Models/intervention/TaskZoneModel';
import ZoneContactModel           from 'Models/intervention/ZoneContactModel';
import ContractModel              from 'Models/sales/ContractModel';
import QuotationModel             from 'Models/sales/QuotationModel';
import { computed }               from 'mobx';
import AbstractModelXStore        from 'stores/AbstractModelXStore';
import { appStore }               from 'stores';

export default class TaskDashboardStore extends AbstractModelXStore {

	public contactCollection = new ApiCollection(ContactModel);
	public contactTypeCollection = new ApiCollection(ContactTypeModel);
	public contract = new ContractModel();
	public interventionCollection = new ApiCollection(InterventionModel);
	public interventionOperationCollection = new ApiCollection(InterventionOperationModel);
	public interventionStatusCollection = new ApiCollection(InterventionStatusModel);
	public operationCollection = new ApiCollection(OperationModel);
	public quotation = new QuotationModel();
	public task = new TaskModel();
	public taskOperationCollection = new ApiCollection(TaskOperationModel);
	public taskOperationExtraCollection = new ApiCollection(TaskOperationExtraModel);
	public taskOperationExtraMainCollection = new ApiCollection(TaskOperationExtraModel);
	public taskOperationStatusCollection = new ApiCollection(TaskOperationStatusModel);
	public taskOperationTagCollection = new ApiCollection(TagModel);
	public taskTagCollection = new ApiCollection(TaskTagModel);
	public taskTaskTagCollection = new ApiCollection(TaskTaskTagModel);
	public taskZoneCollection = new ApiCollection(TaskZoneModel);
	public zoneContactCollection = new ApiCollection(ZoneContactModel);

	public fetchTaskOperationExtras() {
		const taskOperationIds = this.taskOperationCollection.ids;

		return Promise.all([
			this.taskOperationExtraCollection.setRequiredFilter('extraTaskOperation', taskOperationIds).list(),
			this.taskOperationExtraMainCollection.setRequiredFilter('mainTaskOperation', taskOperationIds).list(),
		]);
	}

	public async fetchTaskOperations() {
		await this.taskOperationCollection.setFilters({ task: this.task.id }).list().then(() => {
			return Promise.all([
				this.fetchTaskOperationExtras(),

				this.interventionOperationCollection
					.listByFromCollection(this.taskOperationCollection, 'id', 'taskOperation')
					.then(async () => {
						await this.interventionCollection
							.listByFromCollection(this.interventionOperationCollection, 'interventionId');
					}),

				this.operationCollection.listByFromCollection(this.taskOperationCollection, 'operationId'),
			]);
		});
	}

	public getTaskOperationLinkedRetour(taskOperation: TaskOperationModel) {
		return this.taskOperationExtraMainCollection.find(to => to.getId('mainTaskOperation') === taskOperation.id);
	}

	public getTaskOperationRetour(taskOperation: TaskOperationModel) {
		return this.taskOperationExtraCollection.find(to => to.getId('extraTaskOperation') === taskOperation.id);
	}

	public async initAsync(id: id, clear = true) {
		if (clear) {
			this.clear();
		}

		this.setIsLoading(true);

		await this.task.setId(id).fetch();

		await this.contactTypeCollection.setSort('position').list({ cache: 3600 });

		const promises: Promise<unknown>[] = [
			this.interventionStatusCollection.list({ cache: 3600 }),
			this.taskOperationStatusCollection.list({ cache: 3600 }),
			this.taskTagCollection.list({ cache: 3600 }),

			this.fetchTaskOperations().then(async () => {
				await Promise.all([
					this.taskZoneCollection.listByFromCollection(this.taskOperationCollection, 'taskZoneId', 'id'),
					this.taskOperationTagCollection.listBy(this.taskOperationCollection.urns, 'targetUrn'),
				]);
			}),
			this.taskTaskTagCollection.listBy([id], 'task'),
		];

		await Promise.all(promises);

		await this._fetchContacts();

		this.setIsLoading(false);
	}

	private async _fetchContacts(): Promise<void> {
		if (this.task.id) {
			await this.zoneContactCollection
				.setFilters({
					operationCategory: this.operationCategoryId,
				})
				.listBy([this.taskZoneCollection.first()?.id], 'taskZone');

			await this.contactCollection
				.setSort('globalContact.firstName')
				.listByFromCollection(this.zoneContactCollection, 'contactId');
		}
	}

	@computed
	public get operationCategory() {
		return appStore.operationCategoryCollection.getById(this.operationCategoryId);
	}

	@computed
	public get operationCategoryId() {
		return this.operationCollection.first()?.operationCategoryId || 0;
	}

	@computed
	public get taskZone() {
		return this.taskZoneCollection.first();
	}

	public get owner() {
		return this.task.owner;
	}

	public get client() {
		return this.task.owner.client;
	}

	public get company() {
		return this.task.owner.clientPartition.company;
	}

	public get taskStatus() {
		return this.task.taskStatus;
	}

	public get taskGroup() {
		return this.task.taskGroup;
	}
}
