import { ApiCollection }        from 'Collections/ApiCollection';
import PagedCollection          from 'Collections/PagedCollection';
import ClientModel              from 'Models/directory/ClientModel';
import CompanyModel             from 'Models/directory/CompanyModel';
import EnterprisePartitionModel from 'Models/directory/EnterprisePartitionModel';
import OwnerModel               from 'Models/intervention/OwnerModel';
import TaskZoneGroupModel       from 'Models/intervention/TaskZoneGroupModel';
import TaskZoneModel            from 'Models/intervention/TaskZoneModel';
import nestedResolvable         from 'decorators/nestedResolvable';
import { action }               from 'mobx';
import { computed }             from 'mobx';
import { observable }           from 'mobx';
import { makeObservable }       from 'mobx';
import AbstractModelXStore      from 'stores/AbstractModelXStore';
import { appStore }             from 'stores';
import { layoutSidebarStore }   from 'stores';
import { whenAsync }            from 'tools/modelxTools';
import navigate                 from 'tools/navigate';

type HierarchyFrom = 'enterprise_partition' | 'client' | 'task_zone' | 'task_zone_group';

export default class EnterpriseHierarchyStore extends AbstractModelXStore {

	public clientCollection = new ApiCollection(ClientModel);
	public companyCollection = new ApiCollection(CompanyModel);
	public enterprisePartition = new EnterprisePartitionModel();
	public ownerCollection = new ApiCollection(OwnerModel);
	public taskZoneCollection = new ApiCollection(TaskZoneModel);
	public taskZoneGroupCollection = new ApiCollection(TaskZoneGroupModel);

	@observable
	private _currentFromType?: HierarchyFrom;

	@observable
	private _currentId?: id;

	public constructor() {
		super();

		makeObservable(this);
	}

	public clearSidebar() {
		const href = location.href;

		if (
			!href.includes(navigate.toList(TaskZoneModel) + '/')
			&& !href.includes(navigate.toList(ClientModel) + '/')
			&& !href.includes(navigate.toList(EnterprisePartitionModel) + '/')
		) {
			layoutSidebarStore.setContent(null);
		}
	}

	public async fetchAsync() {
		if (this._checkIsAlreadyFetched()) {
			return;
		}

		this.clear();

		const enterprisePartitionId = await this._getEnterprisePartitionIdAsync();

		await Promise.all([
			await this.enterprisePartition.setId(enterprisePartitionId).fetch().then(async () => {
				await whenAsync(() => this.enterprisePartition.enterprise.isLoaded);

				if (this.enterprisePartition.enterprise.getId('enterpriseGroup')) {
					await whenAsync(() => this.enterprisePartition.enterprise.enterpriseGroup.isLoaded);
				}
			}),

			await this.fetchClientsAsync(enterprisePartitionId),
		]);
	}

	@nestedResolvable()
	public get enterprise() {
		return this.enterprisePartition.enterprise;
	}

	@nestedResolvable()
	public get enterpriseGroup() {
		return this.enterprisePartition.enterprise.enterpriseGroup;
	}

	public fetchClientsAsync = async (enterprisePartitionId: id = this.enterprisePartition.id) => {
		if (!enterprisePartitionId) {
			// TODO: see if it pop in sentry
			throw new Error('Inside EnterpriseHierarchyStore: enterprisePartitionId is null or undefined');
		}

		await this.clientCollection
			.setSort('clientPartition.company.name')
			.setFilter('clientPartition.enterprisePartition', enterprisePartitionId)
			.setFilter('enabled', '1')
			.setFilter('subPartitionUrn', appStore.subPartitionUrn)
			.list();

		await this.clientCollection.whenIsLoaded(c => c.clientPartition);

		await Promise.all([
			this.companyCollection.listBy(this.clientCollection.map(c => c.clientPartition.getId('company'))),

			this.fetchTaskZonesAsync(),
		]);
	};

	public fetchTaskZonesAsync = async () => {
		await this.ownerCollection.listByFromCollection(this.clientCollection, 'urn', 'clientUrn');

		await this.taskZoneCollection
			.setSort('name')
			.setFilter('owner.ownerSubPartition.subPartitionUrn', appStore.subPartitionUrn)
			.listByFromCollection(this.ownerCollection, 'id', 'owner');

		await this.taskZoneGroupCollection
			.setSort('name')
			.listBy(this.taskZoneCollection.map(m => m.getId('taskZoneGroup')));
	};

	@action
	public setFrom(id: id, from: HierarchyFrom) {
		this._currentId = id;
		this._currentFromType = from;

		return this;
	}

	@computed
	public get current() {
		switch (this._currentFromType) {
			case 'enterprise_partition':
				return this.enterprisePartition;
			case 'client':
				return this.client;
			case 'task_zone':
				return this.taskZone;
			case 'task_zone_group':
				return this.taskZoneGroup;
		}

		return null;
	}

	@computed
	public get client() {
		if (this._currentFromType === 'client') {
			return this.clientCollection.getById(this._currentId || '');
		}

		if (this._currentFromType === 'task_zone') {
			const ownerId = this.taskZone?.getId('owner');
			const owner = this.ownerCollection.getById(ownerId || '');

			return this.clientCollection.getById(owner?.clientId || '');
		}

		return null;
	}

	@computed
	public get taskZone() {
		if (this._currentFromType === 'task_zone') {
			return this.taskZoneCollection.getById(this._currentId || '');
		}

		if (this._currentFromType === 'task_zone_group') {
			return this.taskZoneCollection.find(tz => tz.taskZoneGroup.id === this._currentId);
		}

		return null;
	}

	@computed
	public get taskZoneGroup() {
		if (this.taskZone && this.taskZone.getId('taskZoneGroup')) {
			return this.taskZoneGroupCollection.getById(this.taskZone.getId('taskZoneGroup'));
		}

		if (this._currentFromType === 'task_zone_group') {
			return this.taskZoneGroupCollection.getById(this._currentId || '');
		}

		return null;
	}

	@computed
	public get company() {
		if (this.client) {
			return this.companyCollection.getById(this.client.clientPartition.getId('company'));
		}

		return null;
	}

	@computed
	public get currentIri() {
		return this.current?.iri || '';
	}

	private _checkIsAlreadyFetched() {
		if (this._currentFromType === 'client') {
			return !!this.client;
		}

		if (this._currentFromType === 'enterprise_partition') {
			return this.enterprisePartition.id.toString() === this._currentId?.toString();
		}

		if (this._currentFromType === 'task_zone') {
			return !!this.taskZone;
		}

		if (this._currentFromType === 'task_zone_group') {
			return this.taskZoneGroup?.id === this._currentId;
		}

		return false;
	}

	private async _getEnterprisePartitionIdAsync(): Promise<id> {
		switch (this._currentFromType) {
			case 'client':
				const client = new ClientModel({ id: this._currentId });

				await client.fetch();

				await whenAsync(() => client.clientPartition.isLoaded);

				return client.clientPartition.getId('enterprisePartition');

			case 'enterprise_partition':
				return this._currentId || '';

			case 'task_zone':
				const taskZone = new TaskZoneModel({ id: this._currentId });

				await taskZone.fetch();

				await whenAsync(() => taskZone.owner.client.clientPartition.isLoaded);

				return taskZone.owner.client.clientPartition.getId('enterprisePartition');

			case 'task_zone_group': {
				const taskZoneCollection = new PagedCollection(TaskZoneModel);
				taskZoneCollection.setItemsPerPage(1);
				taskZoneCollection.setRequiredFilter('taskZoneGroup', this._currentId);
				await taskZoneCollection.list();
				const taskZone = taskZoneCollection.first();
				if (taskZone) {
					await whenAsync(() => taskZone.owner.client.clientPartition.isLoaded);
					return taskZone.owner.client.clientPartition.getId('enterprisePartition');
				}
			}
		}

		return '';
	}

	@computed
	public get taskZoneCollectionByClient(): Record<NonNullable<id>, ApiCollection<TaskZoneModel>> {
		return this.clientCollection.reduce((acc, client) => {
			const owner = this.ownerCollection.find(o => o.getId('client') === client.id);

			return {
				...acc,
				[client.id]: this.taskZoneCollection.createVirtualCollection(taskZone => {
					return !!owner && taskZone.getId('owner') === owner.id;
				}),
			};
		}, {});
	}
}
