import TaskModel                     from 'Models/intervention/TaskModel';
import SourceModel                   from 'Models/invoice/SourceModel';
import ItemEventExecutionStatusModel from 'Models/sales/ItemEventExecutionStatusModel';
import QuotationItemModel            from 'Models/sales/QuotationItemModel';
import doc                           from 'decorators/doc';
import model                         from 'decorators/model';
import nestedResolvable              from 'decorators/nestedResolvable';
import { Timestampable }             from 'helpers/traits';
import { Blamable }                  from 'helpers/traits';
import SalesPrivateApiModel          from 'modelx/models/abstracts/SalesPrivateApiModel';

@model.staticLabel('QuotationItemEvent')
@model.urnResource('quotation_item_event')
@doc.path('/quotation_item_events/{?id}')
export default class QuotationItemEventModel extends Blamable(Timestampable(SalesPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'itemEventItemActivities.itemEventItemActivityBillingStatus': id;
		'itemEventItemActivities.itemEventItemActivityBillingStatus.reference': SalesItemEventItemActivityBillingStatusReference;
		'quotationItem': id;
		'quotationItem.quotation': id;
		'quotationItemEventStatus': id;
		'quotationItemEventStatus.reference': SalesQuotationItemEventStatusReference;
		'sourceUrn': Urn;
	}> = {};

	public _sorts: ModelSortsExtended<unknown> = {};

	@doc.model(ItemEventExecutionStatusModel) declare itemEventExecutionStatus: ItemEventExecutionStatusModel;
	@doc.model(QuotationItemModel) declare quotationItem: QuotationItemModel;
	@doc.date declare scheduleEndDate: Moment;
	@doc.date declare scheduleStartDate: Moment;
	@doc.modelUrn(SourceModel) declare source: SourceModel;
	@doc.modelUrn(TaskModel) declare task: TaskModel;

	@nestedResolvable()
	public get quotation() {
		return this.quotationItem.quotation;
	}
}