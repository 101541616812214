import doc                  from 'decorators/doc';
import model                from 'decorators/model';
import { Timestampable }    from 'helpers/traits';
import SalesPrivateApiModel from 'modelx/models/abstracts/SalesPrivateApiModel';

@model.cacheDuration()
@model.staticLabel('Statut d\'exploitation')
@model.urnResource('item_event_item_activity_execution_status')
@doc.path('/item_event_item_activity_execution_statuses/{?id}')
export default class ItemEventItemActivityExecutionStatusModel extends Timestampable(SalesPrivateApiModel) {
	public _filters: ModelFiltersExtended<{
		'reference': SalesItemEventItemActivityExecutionStatusReference;
	}> = {};

	public _sorts: ModelSortsExtended<unknown> = {};

	@doc.string declare label: string;
	@doc.string declare reference: SalesItemEventItemActivityExecutionStatusReference;

	public get position() {
		switch (this.reference) {
			case 'done':
				return 4;
			case 'canceled':
				return 5;
			case 'suspended':
				return 3;
			case 'in_progress':
				return 2;
			case 'todo':
				return 1;
			case 'created':
				return 0;
		}
	}

	public get color() {
		switch (this.reference) {
			case 'done':
				return '#3ae374';
			case 'canceled':
				return '#ff9f1a';
			case 'suspended':
				return '#ffb8b8';
			case 'in_progress':
				return '#7158e2';
			case 'todo':
				return '#fff200';
			case 'created':
				return '#17c0eb';
		}
	}
}