import EnterpriseModel        from 'Models/directory/EnterpriseModel';
import CurrencyModel          from 'Models/invoice/CurrencyModel';
import InvoicingOwnerModel    from 'Models/invoice/InvoicingOwnerModel';
import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import { Timestampable }      from 'helpers/traits';
import { Blamable }           from 'helpers/traits';
import { computed }           from 'mobx';
import InvoicePrivateApiModel from 'modelx/models/abstracts/InvoicePrivateApiModel';
import { getCountryName }     from 'tools/Countries';

@model.staticLabel('Adresse de facturation', false)
@model.urnResource('invoicing_group')
@doc.path('/invoicing_groups/{?id}')
export default class InvoicingGroupModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'archived': '0' | '1';
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'invoicingOwner': id;
		'invoicingOwner.enterpriseUrn': Urn;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'position': string;
		'updatedAt': string;
	}> = {};

	@doc.string declare address: string;
	@doc.string declare addressBis: string;
	@doc.boolean declare archived: boolean;
	@doc.string declare city: string;
	@doc.string declare country: string;
	@doc.model(CurrencyModel) declare currency: CurrencyModel;
	@doc.string declare denomination: string;
	@doc.string declare denominationBis: string;
	@doc.number declare dueDateDelay: number;
	@doc.boolean declare endOfMonth: boolean;
	@doc.modelUrn(EnterpriseModel) declare enterprise: EnterpriseModel;
	@doc.model(InvoicingOwnerModel) declare invoicingOwner: InvoicingOwnerModel;
	@doc.string declare name: string;
	@doc.number declare position: number;
	@doc.string declare zipCode: string;

	@computed
	public get enterprisePartitionUrn(): string {
		return this.get('enterprisePartitionUrn', '');
	}

	@computed
	public get countryName() {
		return getCountryName(this.country);
	}

	public get fullAddress() {
		return [this.address, this.zipCode, this.city, this.countryName].filter(s => !!s).join(' ');
	}
}