import doc                         from 'decorators/doc';
import model                       from 'decorators/model';
import { Timestampable }           from 'helpers/traits';
import { Blamable }                from 'helpers/traits';
import InterventionPrivateApiModel from 'modelx/models/abstracts/InterventionPrivateApiModel';

@model.staticLabel('TaskOperationExtraReason')
@model.urnResource('task_operation_extra_reason')
@doc.path('/task_operation_extra_reasons/{?id}')
export default class TaskOperationExtraReasonModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'label': string;
		'reference': InterventionTaskOperationExtraReasonReference;
		'enabled': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'position': string;
	}> = {};

	@doc.boolean declare enabled: boolean;
	@doc.string declare label: string;
	@doc.number declare position: number;
	@doc.string declare reference: InterventionTaskOperationExtraReasonReference;
}
